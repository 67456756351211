<template>
  <div class="shareMask">
    <div class="flex-re">
      <div class="bg_share_guide"></div>
    </div>
    <div class=" fw7 text-white tc text-lg tracking-wide">
      <p class="py-1">1. 点击右上角更多按钮</p>
      <p>2. 选择在浏览器中打开</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "shareMask",
  components: {},
  data() {
    return {
    };
  },
};
</script>

<style lang="less" src='./index.less'>
</style>




