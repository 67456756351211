import html2canvas from "html2canvas";

// 创建海报
export const mixinHtml2Canvas = {
    data() {
      
    },
    
    methods: {
        initPoster(refBox) {
            const posterBox = this.$refs[refBox]
            return new Promise(function (resolve, reject) {
                html2canvas(posterBox, {
                    // 转换为图片
                    allowTaint: true,
                    useCORS: true // 解决资源跨域问题
                }).then((canvas) => {
                    canvas.style.height = 100 * canvas.style.height;
                    canvas.style.width = 100 * canvas.style.width;
                    let imgUrl = canvas.toDataURL("image/png"); //这里通过canvas的toDataURL方法把它转换成base64编码。
                    // console.log(imgUrl)
                    // return imgUrl
                    resolve(imgUrl)
                }).catch(() => {
                    reject('111111111')
                });
            })
        }
    },
}