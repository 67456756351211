<template>
  <div class="shareEntryPage text-base">
    <div class="banner_top " ref='posterBanner'>
      <div class="pb-1">
        <div class="share_nav_wrap bg_share_nav">
          <div class="app_name text-white fw7 tracking-wider tc pt-5 pb-2">
            <h5>{{appOptions.name}}APP</h5>
          </div>
          <div class="flex-cc">
            <span class="brown_text_1 px-1 py-0.5 bg_theme_linear rounded bg_theme_line">
              考级培训学习一体化服务
            </span>
          </div>

        </div>
        <ul class="share_nav_secord bg_share_nav_secord px-2.5 flex-rb">
          <li class="tc flex-cc" v-for="v in tagList" :key='v.id'>
            <div class="">
              <div class="py-1 bg_tag_nav text-white rounded-sm">{{v.tagName}}</div>
              <p class="brown_text_1 pt-1 text-tiny">{{v.des}}</p>
            </div>
          </li>
        </ul>

        <h6 class="brown_text_2 tc text-tiny mt-1">*技术支持 @南京恰好信息技术有限公司</h6>
      </div>

    </div>
    <!-- <img :src="posterBannerUrl" alt=""> -->

    <div class="share_content_wrap">
      <div class="org_introduce px-2 py-3">
        <div class="bg_org_introduce bg-white fw7 mb-3">
          <div class="bg_org_introduce_head tc flex-cc text-base text-white">
            <span>请扫描下方的二维码</span>
          </div>
          <div>

          </div>
        </div>
        <div class="bg_org_introduce bg-white fw7 ">
          <div class="bg_org_introduce_head tc flex-cc text-base text-white">
            <span>相关介绍</span>
          </div>
          <div class="px-2">
            <p class="text_2em">{{detailInfo.introduction || appOptions.introduction}}</p>
            <span class="brown_text_1 tc flex-ac py-2">
              <i class="iconfont icon-diqu text-base pr-1"></i>
              <i class="text-tiny">{{detailInfo.introduction ? detailInfo.address : appOptions.address}}</i>
            </span>
          </div>

        </div>
      </div>

    </div>
    <HFooterBtn :options='HFooterBtnOptions' @ok='handleEnter' />

  </div>
</template>

<script>
import { appOptions } from "@/config/settingConfig/web.js";
import { isWeixin, isIos } from "@/utils/common";
import { shareData } from "./index.js";
import { HFooterBtn, HOverlay } from "@/components/HVant";
import { CShareMask } from "@/components/comm";
import { fullForm } from "./index.js";
// hooks
import { mixinHtml2Canvas } from "@/hooks/mixin/html2Canvas.js";
import { reqApi } from "@/api/utils/reqApi.js";
export default {
  name: "shareEntryPage",
  mixins: [mixinHtml2Canvas],
  components: {
    HFooterBtn,
  },
  props: {},
  data() {
    return {
      fullForm,
      appOptions,
      tagList: shareData.tagList,
      HFooterBtnOptions: {
        text: "进入APP",
        btnType: "",
      },
      detailInfo: {},

      showOverlay: false,
      posterBannerUrl: "",
    };
  },

  mounted() {
    this.loadDetailAPI();
    if (!isIos()) {
      // let downLoadUrl = appOptions.downLoadUrl;
      // window.location.href = downLoadUrl;
    }
  },

  methods: {
    handleEnter() {
      console.log("进入app");
      this.createPosterApi();
    },
    async createPosterApi() {
      const url = await this.initPoster("posterBanner");
      console.log(url, "");
      this.posterBannerUrl = url;
    },
    async loadDetailAPI() {
      const id = this.$route.query.deptId;
      if (!id) {
        console.log("没有机构认证");
        return;
      }
      let { data } = await reqApi("deptInfoDetail", id, "paramsJoin");
      if (data) {
        this.detailInfo = data;
      }
    },
  },
};
</script>
<style lang="less" src='./index.less'>
</style>


