/**
 * 
 * @export 接口调用处理
 * @param  {string} name 接口名称
 * @param  {Object} data 传递参数
 * @param  {String} type['fullPath*全路径', 'paramsJoin*参数拼接']  默认不是
 * @return 接口调用
 */
export async function reqApi(name, data, type) {
    console.log(window.api[name], 'window.api[name]', name)
    switch (type) {
        case 'fullPath': //全路径
            return window.$req(name, data)
        case 'paramsJoin': //参数拼接
            return window.$req(window.api[name] + '/' + data)
        // case 'paramsAndJoin':
        //     return window.$req(window.api[name] + '/' + data.id)
        default: //api名称
            return window.$req(window.api[name], data);
    }
}

// 字典表获取
export async function dictApi(codeType) {
    return window.$req(window.api['dict'] + '/' + codeType)
}
